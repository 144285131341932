import { useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button, Logo } from '@use-gateway/components';
import { getLoginUrl } from '@use-gateway/utils';
import { Burger } from './burger';
import { Menu } from './menu';
import { Nav } from './nav';

const HeaderWrapper = styled.header`
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 112px;
  background-color: #fff;

  ${down('md')} {
    position: fixed;
    top: 0;
    height: 78px;
    box-shadow: 6px 6px 40px #eaebf4;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1300px;
  max-width: calc(100% - (150px * 2));

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }
`;

const HeaderLogo = styled.div`
  font-size: 0;
`;

const HeaderRightContent = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderButton = styled.a`
  width: 200px;
  height: 48px;
  margin-left: 55px;
  text-decoration: none;

  ${down('lg')} {
    margin-left: 32px;
  }

  ${down('md')} {
    display: none;
  }
`;

export function HeaderLanding() {
  const [isMenu, setIsMenu] = useState(false);

  return (
    <>
      <HeaderWrapper>
        <HeaderContent>
          <HeaderLogo onClick={() => setIsMenu(false)}>
            <Logo />
          </HeaderLogo>
          <HeaderRightContent>
            <Nav />
            <HeaderButton href={getLoginUrl()}>
              <Button variant={'outline'}>Sign in</Button>
            </HeaderButton>
            <Burger open={isMenu} setOpen={setIsMenu} />
          </HeaderRightContent>
        </HeaderContent>
      </HeaderWrapper>
      <Menu isMenu={isMenu} setIsMenu={setIsMenu} />
    </>
  );
}
