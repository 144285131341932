import { down } from 'styled-breakpoints';
import styled from 'styled-components';

interface SolutionWrapperProps {
  isActive: boolean;
}

export const SolutionWrapper = styled.div<SolutionWrapperProps>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  width: 100%;
  opacity: 0;
  animation: ani 2s forwards;

  @keyframes ani {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const SolutionContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 570px;
  max-width: 430px;
  padding: 40px 0;

  ${down('md')} {
    max-width: 100%;
    min-height: auto;
    padding: 0;
  }
`;

export const SolutionText = styled.div`
  margin-bottom: 54px;

  ${down('md')} {
    margin-bottom: 36px;
  }
`;

export const SolutionTitle = styled.h3`
  margin: 0 0 54px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;

  ${down('md')} {
    margin-bottom: 36px;
    font-size: 28px;
    line-height: 1.14;
  }
`;

export const SolutionDescription = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.dark[40]};

  ${down('md')} {
    font-size: 16px;
    line-height: 1.25;
  }
`;

export const SolutionLink = styled.a`
  display: block;
  max-width: 235px;
  text-decoration: none;

  ${down('md')} {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
  }

  button {
    height: 70px;
    box-shadow: 0 27px 54px rgba(72, 90, 255, 0.3);

    ${down('md')} {
      height: 56px;
    }
  }
`;
