/* eslint-disable @next/next/no-img-element */
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { IconEmail2, IconTelegram } from '@use-gateway/icons';
import { useIntersectionObserver } from '@use-gateway/utils';
import point from '../assets/icons/point.svg';
import bg from '../assets/img/contact/bg-benefits.png';
import img from '../assets/img/contact/contact.png';
import imgX2 from '../assets/img/contact/contact@2x.png';
import imgX2Webp from '../assets/img/contact/contact@2x.webp';

const ContactUsWrapper = styled.section`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 64px 0;

  ${down('md')} {
    padding: 30px 0 48px;
  }

  h2 {
    width: fit-content;
    margin: 0 auto 54px;
    font-weight: 600;
    font-size: 32px;

    ${down('md')} {
      margin-bottom: 18px;
      font-size: 24px;
    }
  }
`;

const ContactUsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  ${down('md')} {
    grid-template-columns: 1fr;
  }
`;

const ContactUsImg = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -110px;

  ${down('md')} {
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 630px;
    pointer-events: none;
  }
`;

const ContactUsContentRight = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;

  ${down('md')} {
    margin: 0 auto;
  }
`;

const BenefitsCard = styled.div`
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  align-self: center;
  width: 100%;
  max-width: 412px;
  border-radius: 30px;
  box-shadow: 8.40235px 8.40235px 60px rgba(0, 0, 0, 0.15);
  padding: 48px 24px 36px;
  background-color: ${({ theme }) => theme.colors.light[100]};

  ${down('md')} {
    padding: 36px 16px;
  }

  ul {
    margin: 0 0 36px;
    padding: 0;
    list-style: none;

    ${down('md')} {
      margin-bottom: 24px;
    }

    li {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 1.3;

      ${down('md')} {
        font-size: 18px;
        line-height: 1.44;
      }

      &:after {
        content: '';
        display: block;
        width: 24px;
        min-width: 24px;
        height: 24px;
        background-image: url(${point});
        background-size: cover;
      }
    }
  }

  a {
    text-decoration: none;
  }

  button {
    height: 70px;
    box-shadow: 0px 27px 54px rgba(72, 90, 255, 0.3);

    ${down('md')} {
      height: 56px;
    }
  }
`;

const BenefitsCardTitle = styled.h3`
  margin: 0 0 36px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 32px;

  ${down('md')} {
    margin-bottom: 24px;
    font-size: 24px;
  }
`;

const ContactCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 36px;
  padding: 24px 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.light[80]};

  ${down('md')} {
    margin-bottom: 24px;
    padding: 16px;
  }

  h3 {
    margin: 0 0 16px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.dark[100]};
    text-decoration: none;

    ${down('md')} {
      font-size: 18px;
    }

    ${down('xs')} {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.info[100]};
    }

    svg {
      font-size: 24px;
      color: ${({ theme }) => theme.colors.info[100]};
    }
  }
`;

const BenefitsBg = styled.div`
  position: absolute;
  left: 210px;
  bottom: -40px;
  width: 512px;
  height: 512px;
  background-image: url(${bg.src});
  background-repeat: no-repeat;
  background-size: 100% auto;

  ${down('md')} {
    display: none;
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: -150px;

  ${down('md')} {
    top: -96px;
  }
`;

export function ContactUs() {
  const { ref, isVisible } = useIntersectionObserver();

  return (
    <ContactUsWrapper>
      <div ref={ref} className={isVisible ? 'animation-block active' : 'animation-block'}>
        <h2>Contact us</h2>
        <ContactUsContent>
          <ContactUsImg>
            <picture>
              <source type="image/webp" srcSet={imgX2Webp.src} />
              <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
            </picture>
          </ContactUsImg>

          <ContactUsContentRight>
            <BenefitsCard>
              <Anchor id={'contact'} />
              <BenefitsCardTitle>Your benefits</BenefitsCardTitle>
              <ul>
                <li>Owner controlled wallet</li>
                <li>The most popular cryptocurrencies</li>
                <li>Multilingual payment page</li>
                <li>Instant withdrawals</li>
              </ul>
              <ContactCard>
                <h3>Contact us</h3>
                <a href="https://telegram.me/usegateway" target="_blank" rel="noreferrer">
                  <IconTelegram />
                  @usegateway
                </a>
                <a href="mailto:support@usegateway.group">
                  <IconEmail2 />
                  support@usegateway.group
                </a>
              </ContactCard>
              <a href="mailto:support@usegateway.group">
                <Button>Submit a request</Button>
              </a>
            </BenefitsCard>
            <BenefitsBg />
          </ContactUsContentRight>
        </ContactUsContent>
      </div>
    </ContactUsWrapper>
  );
}
