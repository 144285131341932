import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ItemsSolution } from './index';

const SolutionsNavWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 64px;
  overflow: auto;

  ${down('md')} {
    margin-bottom: 42px;
    gap: 14px;
  }
`;

interface ItemProps {
  isActive?: boolean;
}

const Item = styled.div<ItemProps>`
  position: relative;
  padding-bottom: 4px;
  white-space: nowrap;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.dark[100] : theme.colors.dark[40])};
  cursor: pointer;

  ${down('md')} {
    font-size: 18px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    width: 43px;
    height: 4px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.info[100]};
  }
`;

interface SolutionsNavProps {
  currentValue: ItemsSolution;
  setCurrentValue: (value: ItemsSolution) => void;
}

export function SolutionsNav({ currentValue, setCurrentValue }: SolutionsNavProps) {
  return (
    <SolutionsNavWrapper>
      <Item
        isActive={currentValue === ItemsSolution.payments}
        onClick={() => setCurrentValue(ItemsSolution.payments)}>
        Payments
      </Item>
      <Item
        isActive={currentValue === ItemsSolution.deposits}
        onClick={() => setCurrentValue(ItemsSolution.deposits)}>
        Deposits
      </Item>
      <Item
        isActive={currentValue === ItemsSolution.invoices}
        onClick={() => setCurrentValue(ItemsSolution.invoices)}>
        Invoices
      </Item>
      <Item
        isActive={currentValue === ItemsSolution.whiteLabel}
        onClick={() => setCurrentValue(ItemsSolution.whiteLabel)}>
        White Label
      </Item>
    </SolutionsNavWrapper>
  );
}
