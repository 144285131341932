import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { IconTelegram, IconTwitter } from '@use-gateway/icons';
import { useIntersectionObserver } from '@use-gateway/utils';
import imgBg from '../assets/img/bg-news/bg-news.png';
import imgBgWebp from '../assets/img/bg-news/bg-news.webp';

const OurNewsWrapper = styled.section`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  min-height: calc(48px + 64px + 500px + 40px + 64px);
  padding: 48px 0 64px;
  background-color: ${({ theme }) => theme.colors.info[100]};

  ${down('md')} {
    height: auto;
    min-height: auto;
    padding: 38px 0 58px;
  }
`;

const OurNewContent = styled.div`
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  height: 100%;
  margin: 0 auto;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }
`;

const OurNewsTitle = styled.h2`
  margin: 0 0 64px;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.light[100]};

  ${down('md')} {
    margin-bottom: 28px;
    font-size: 24px;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 104px);
  min-height: 500px;
  background-image: url(${imgBg.src});
  background-image: image-set(${imgBg.src} 1x, '${imgBgWebp.src}' 2x);
  background-repeat: no-repeat;
  background-size: 100% auto;

  ${down('md')} {
    background-image: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${down('md')} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 240px;
  margin-right: 80px;

  ${down('md')} {
    width: 100%;
    margin-right: 0;
    padding-top: 100px;
    background-image: url(${imgBg.src});
    background-image: image-set(${imgBg.src} 1x, ${imgBgWebp.src} 2x);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

const Button = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  width: 100%;
  min-width: 240px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.light[100]};
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    transform: scale(0.98);
  }

  ${down('md')} {
    max-width: 240px;
    margin: 0 auto;
  }

  svg {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.info[100]};
  }
`;

const SectionNews = styled.div`
  position: relative;
  width: 100%;
  max-width: 380px;
  height: 100%;

  ${down('md')} {
    height: auto;
  }
`;

const NewsShadow = styled.div`
  position: absolute;
  width: 100%;
  height: 74px;
  background: linear-gradient(180deg, #485aff 0%, rgba(72, 90, 255, 0) 100%);

  &:last-child {
    bottom: 0;
    transform: rotate(-180deg);
  }
`;

const News = styled.div`
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 500px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  iframe {
    color-scheme: none !important;
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: 0px;

  ${down('md')} {
    top: -76px;
  }
`;

export function OurNews() {
  const { ref, isVisible } = useIntersectionObserver();
  const idPosts = [19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7];

  return (
    <OurNewsWrapper>
      <Anchor id={'social'} />
      <OurNewContent ref={ref} className={isVisible ? 'animation-block active' : 'animation-block'}>
        <OurNewsTitle>Our news</OurNewsTitle>
        <WrapperContent>
          <Content>
            <Buttons>
              <Button href={'https://t.me/usegateway_payments'} target={'_blank'} rel="noreferrer">
                <IconTelegram />
                Follow us on Telegram
              </Button>
              <Button href={'https://twitter.com/use_gateway'} target={'_blank'} rel="noreferrer">
                <IconTwitter />
                Follow us on Twitter
              </Button>
            </Buttons>

            <SectionNews>
              <NewsShadow />
              <News>
                {idPosts.map((id) => (
                  <div
                    key={id}
                    dangerouslySetInnerHTML={{
                      __html: `
                <script 
                  async 
                  src="https://telegram.org/js/telegram-widget.js?22"
                  data-telegram-post="usegateway_payments/${id}"
                  data-userpic="false"
                  data-width="100%"></script>
              `,
                    }}
                  />
                ))}
              </News>
              <NewsShadow />
            </SectionNews>
          </Content>
        </WrapperContent>
      </OurNewContent>
    </OurNewsWrapper>
  );
}
