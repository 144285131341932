/* eslint-disable @next/next/no-img-element */
import { down } from 'styled-breakpoints';
import { Button, TrustBox } from '@use-gateway/components';
import { IconCheck } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { getRegisterUrl, useIntersectionObserver } from '@use-gateway/utils';
import background from '../assets/img/jumbotron/jumbotron.png';
import backgroundX2 from '../assets/img/jumbotron/jumbotron@2x.png';
import backgroundX2Webp from '../assets/img/jumbotron/jumbotron@2x.webp';

export const JumbotronWrapper = styled.section`
  display: flex;
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  min-height: calc(100vh - 112px);
  margin: 0 auto;

  @media (orientation: portrait) {
    min-height: auto !important;
  }

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  ${down('md')} {
    min-height: auto;
    padding: 36px 0 24px;
  }

  a {
    display: block;
    max-width: 300px;
    text-decoration: navajowhite;

    button {
      height: 70px;

      ${down('md')} {
        height: 56px;
      }
    }
  }
`;

const JumbotronContentLeft = styled.div`
  align-self: center;
  width: 540px;
  min-width: 540px;
  height: fit-content;
  padding-bottom: 32px;

  ${down('md')} {
    width: 100%;
    min-width: auto;
    max-width: 100%;
    padding-bottom: 0;
  }

  h1 {
    width: 100%;
    margin: 0 0 36px;
    padding-top: 24px;
    font-weight: 700;
    font-size: 34px;
    line-height: 1.18;

    ${down('md')} {
      margin: 0 0 25px;
      padding-top: 0;
      text-align: center;
      font-size: 24px;
      line-height: 1.06;
    }

    span {
      color: ${({ theme }) => theme.colors.info[100]};
    }
  }

  ul {
    margin: 0 0 96px;
    padding-left: 8px;
    font-size: 24px;
    line-height: 1.5;
    list-style: none;

    ${down('md')} {
      margin: 0 0 32px;
      font-size: 16px;
      font-weight: 500;
    }

    li {
      display: flex;
      max-width: 380px;

      ${down('md')} {
        max-width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      svg {
        min-width: 36px;
        margin-right: 12px;
        margin-bottom: -8px;
        font-size: 36px;
        color: ${({ theme }) => theme.colors.info[100]};

        ${down('md')} {
          min-width: 24px;
          margin-bottom: 0;
          font-size: 24px;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  a {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const BtnSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${down('md')} {
    flex-direction: column;
    gap: 0;
    max-width: 360px;
    margin: 0 auto;
  }

  a {
    min-width: 235px;
    margin-bottom: 0;

    button {
      box-shadow: 0px 27px 54px rgba(72, 90, 255, 0.3);

      ${down('md')} {
        height: 56px;
      }
    }

    ${down('md')} {
      width: 100%;
      max-width: 100%;
      margin-bottom: 16px;
    }
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.light[40]};

    ${down('md')} {
      text-align: center;
    }
  }
`;

const SupportSection = styled.div`
  width: fit-content;
  margin-top: 32px;

  ${down('md')} {
    margin: 16px auto 0;
  }

  & > label {
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  & > div {
    width: fit-content;
    margin: 0 auto;
  }
`;

interface JumbotronContentImgProps {
  isMob?: boolean;
}

const JumbotronContentImg = styled.div<JumbotronContentImgProps>`
  display: ${({ isMob }) => (isMob ? 'none' : 'flex')};
  align-items: flex-end;
  width: 100%;
  font-size: 0;

  ${down('md')} {
    display: ${({ isMob }) => (isMob ? 'flex' : 'none')};
    box-sizing: border-box;
    padding: 0 6px;
  }

  img {
    width: 100%;
    pointer-events: none;

    ${down('md')} {
      width: 316px;
      height: 318px;
    }

    ${down('xs')} {
      width: 276px;
      height: 278px;
    }
  }
`;

export function Jumbotron() {
  const { ref, isVisible } = useIntersectionObserver();

  return (
    <JumbotronWrapper
      ref={ref}
      className={isVisible ? 'animation-block active' : 'animation-block'}>
      <JumbotronContentLeft>
        <h1>
          <span>Crypto</span> Payments For <span>Business</span>
        </h1>

        <ul>
          <li>
            <IconCheck />
            <p>Transaction fee from 0,4%</p>
          </li>
          <li>
            <IconCheck />
            <p>99% uptime</p>
          </li>
          <li>
            <IconCheck />
            <p>Convenient payment form</p>
          </li>
        </ul>

        <BtnSection>
          <JumbotronContentImg isMob>
            <picture>
              <source type="image/webp" srcSet={backgroundX2Webp.src} />
              <img
                src={background.src}
                srcSet={`${background.src}, ${backgroundX2.src} 2x`}
                alt=""
              />
            </picture>
          </JumbotronContentImg>
          <a href={getRegisterUrl()}>
            <Button>Get Started</Button>
          </a>
          <p>
            No limits.
            <br />
            No account verification.
          </p>
        </BtnSection>

        <SupportSection>
          <label>#1 Support in Crypto</label>
          <div>
            <TrustBox />
          </div>
        </SupportSection>
      </JumbotronContentLeft>
      <JumbotronContentImg>
        <picture>
          <source type="image/webp" srcSet={backgroundX2Webp.src} />
          <img src={background.src} srcSet={`${background.src}, ${backgroundX2.src} 2x`} alt="" />
        </picture>
      </JumbotronContentImg>
    </JumbotronWrapper>
  );
}
