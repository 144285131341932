import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import { getRegisterUrl, useSsrDetector } from '@use-gateway/utils';
import {
  SolutionContent,
  SolutionDescription,
  SolutionLink,
  SolutionText,
  SolutionTitle,
  SolutionWrapper,
} from './styles';
import imgMob from '../../assets/img/solutions/invoices/invoices-m.png';
import imgMobX2 from '../../assets/img/solutions/invoices/invoices-m@2x.png';
import imgMobX2Webp from '../../assets/img/solutions/invoices/invoices-m@2x.webp';
import img from '../../assets/img/solutions/invoices/invoices.png';
import imgX2 from '../../assets/img/solutions/invoices/invoices@2x.png';
import imgX2Webp from '../../assets/img/solutions/invoices/invoices@2x.webp';

const SectionImg = styled.div`
  ${down('md')} {
    width: 286px;
    margin: 0 auto 36px;
  }

  img {
    display: block;
    max-width: 100%;
    margin-top: -26px;
    margin-bottom: -32px;
  }
`;

interface SolutionInvoicesProps {
  isActive: boolean;
}

export function SolutionInvoices({ isActive }: SolutionInvoicesProps) {
  const { isDown, isUp } = useBreakpoints();
  const ssr = useSsrDetector();

  if (ssr) return null;

  return (
    <SolutionWrapper isActive={isActive}>
      <SolutionContent>
        <SolutionText>
          <SolutionTitle>Invoices are a convenient invoicing solution</SolutionTitle>
          <SolutionDescription>
            A great solution when you need to send a payment link to a customer – for example, by
            email. The invoice can be paid at any time before the expiration term set in advance.
          </SolutionDescription>
        </SolutionText>
        {isDown(Breakpoints.md) && (
          <SectionImg>
            <picture>
              <source type="image/webp" srcSet={imgMobX2Webp.src} />
              <img src={imgMob.src} srcSet={`${imgMob.src}, ${imgMobX2.src} 2x`} alt="" />
            </picture>
          </SectionImg>
        )}
        <SolutionLink href={getRegisterUrl()}>
          <Button>Get Started</Button>
        </SolutionLink>
      </SolutionContent>
      {isUp(Breakpoints.md) && (
        <SectionImg>
          <picture>
            <source type="image/webp" srcSet={imgX2Webp.src} />
            <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
          </picture>
        </SectionImg>
      )}
    </SolutionWrapper>
  );
}
