/* eslint-disable @next/next/no-img-element */
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { IconX } from '@use-gateway/icons';
import { getLoginUrl, useLanding } from '@use-gateway/utils';
import presentation from './assets/img/presentation.png';
import presentationX2 from './assets/img/presentation@2x.png';
import presentationX2Webp from './assets/img/presentation@2x.webp';

const CardCreateWalletWrapper = styled.div`
  box-sizing: border-box;
  z-index: 100;
  position: fixed;
  top: 0;
  left: auto;
  bottom: 0;
  right: 32px;
  width: 340px;
  max-width: calc(100% - 32px);
  height: fit-content;
  margin: auto;
  padding: 40px 24px 24px;
  border-radius: 32px;
  box-shadow: 12px 12px 98px rgba(0, 0, 0, 0.18);
  background-color: ${({ theme }) => theme.colors.light[100]};

  ${down('md')} {
    right: 0;
    left: 0;
  }

  img {
    display: block;
    width: 100%;
    max-width: 155px;
    margin: 0 auto;
  }

  h3 {
    margin-bottom: 16px;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.27;
  }

  p {
    margin-bottom: 32px;
    text-align: center;
  }

  a {
    text-decoration: none;
  }
`;

const CardCreateWalletImg = styled.div`
  position: absolute;
  top: 20px;
  right: 24px;
  font-size: 20px;
  color: #29253c;
  cursor: pointer;
`;

export function CardCreateWallet() {
  const { closeNotification } = useLanding();

  return (
    <CardCreateWalletWrapper>
      <CardCreateWalletImg
        onClick={() => {
          closeNotification();
          localStorage.setItem('wallet', 'true');
        }}>
        <IconX />
      </CardCreateWalletImg>
      <picture>
        <source type="image/webp" srcSet={presentationX2Webp.src} />
        <source srcSet={`${presentationX2.src} 2x`} />
        <img src={presentation.src} alt="" className={'pc'} />
      </picture>
      <h3>Welcome to UseGateway!</h3>
      <p>Start accept crypto lightning fast.</p>
      <a href={getLoginUrl()}>
        <Button>Create wallet</Button>
      </a>
    </CardCreateWalletWrapper>
  );
}
