import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import { useSsrDetector } from '@use-gateway/utils';
import {
  SolutionContent,
  SolutionWrapper,
  SolutionText,
  SolutionTitle,
  SolutionDescription,
  SolutionLink,
} from './styles';
import imgMob from '../../assets/img/solutions/whiteLabel/screen-m.png';
import imgMobX2 from '../../assets/img/solutions/whiteLabel/screen-m@2x.png';
import imgMobX2Webp from '../../assets/img/solutions/whiteLabel/screen-m@2x.webp';
import img from '../../assets/img/solutions/whiteLabel/screen.png';
import imgX2 from '../../assets/img/solutions/whiteLabel/screen@2x.png';
import imgX2Webp from '../../assets/img/solutions/whiteLabel/screen@2x.webp';

const SectionImg = styled.div`
  box-shadow: 8.402px 8.402px 60px 0px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  ${down('md')} {
    width: 194px;
    margin: 0 auto 60px;
    box-shadow: 5.42px 5.42px 38.704px 0px rgba(0, 0, 0, 0.15);
  }

  img {
    display: block;
    max-width: 100%;
  }
`;

interface SolutionWhiteLabelProps {
  isActive: boolean;
}

export function SolutionWhiteLabel({ isActive }: SolutionWhiteLabelProps) {
  const { isDown, isUp } = useBreakpoints();
  const ssr = useSsrDetector();

  if (ssr) return null;

  return (
    <SolutionWrapper isActive={isActive}>
      <SolutionContent>
        <SolutionText>
          <SolutionTitle>White Label - establish your own crypto gateway</SolutionTitle>
          <SolutionDescription>
            You have the flexibility to set your own rates for client services. Despite being fully
            branded to your business, we only require a minimal percentage for support and ongoing
            development. With us, there are no initial deposits or regular payments.
          </SolutionDescription>
        </SolutionText>

        {isDown(Breakpoints.md) && (
          <SectionImg>
            <picture>
              <source type="image/webp" srcSet={imgMobX2Webp.src} />
              <img src={imgMob.src} srcSet={`${imgMob.src}, ${imgMobX2.src} 2x`} alt="" />
            </picture>
          </SectionImg>
        )}

        <SolutionLink href="https://telegram.me/usegateway" target="_blank" rel="noreferrer">
          <Button>Contact us</Button>
        </SolutionLink>
      </SolutionContent>

      {isUp(Breakpoints.md) && (
        <SectionImg>
          <picture>
            <source type="image/webp" srcSet={imgX2Webp.src} />
            <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
          </picture>
        </SectionImg>
      )}
    </SolutionWrapper>
  );
}
