import Link from 'next/link';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import {
  // IconAffiliate,
  IconApi,
  IconBag,
  IconChevronUp,
  IconFaq,
  IconGuide,
  IconMessage,
  IconNews,
  IconPayments,
  IconRequest,
  IconRequestMenu,
  IconTestimonials,
  IconToggle,
  IconWallet,
} from '@use-gateway/icons';
import { styledTransition, useNoticeable } from '@use-gateway/utils';

const NavWrapper = styled.nav`
  display: flex;
  gap: 36px;
  margin-left: 32px;

  ${down('lg')} {
    gap: 16px;
  }

  ${down('md')} {
    display: none;
  }
`;

interface NavItemProps {
  widthLabel: number;
}

const NavItem = styled.div<NavItemProps>`
  position: relative;

  & > label {
    display: block;
    width: ${({ widthLabel }) => `${widthLabel}px`};
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 18px;
    transition: 0.1s;

    svg {
      margin-left: 8px;
      margin-bottom: -2px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.info[100]};
      transform: rotate(180deg);
    }
  }

  &:hover > label {
    font-weight: 700;

    svg {
      transform: rotate(0deg);
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 30px;
      height: 4px;
      margin-top: 8px;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.colors.info[100]};
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 290px;
      min-width: 100%;
      height: 60px;
    }
  }

  & > ul {
    position: absolute;
    box-sizing: border-box;
    display: none;
    background-color: ${({ theme }) => theme.colors.light[100]};
    width: 290px;
    margin-top: 24px;
    padding: 16px 8px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    border-radius: 0 16px 16px 16px;
    list-style: none;
  }

  &:hover > ul {
    display: block;
  }
`;

const NavItemLi = styled.li`
  a {
    display: flex;
    gap: 16px;
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.dark[100]};
    text-decoration: none;
    cursor: pointer;
  }

  svg {
    min-width: 24px;
    font-size: 24px;
    ${styledTransition(['color', 'background-color'])}
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.info[100]};
    }
  }
`;

const NavItemLiContent = styled.div`
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  p {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.light[40]};
  }
`;

interface NavLinkProps {
  width?: number;
}

const NavLink = styled.a<NavLinkProps>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-family: 'Nunito', sans-serif;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }
`;

const NewsCount = styled.span`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.light[100]};
`;

export function Nav() {
  const { newsCount, toggleWidget } = useNoticeable();

  return (
    <NavWrapper>
      {
        //-------------------------------------------------------------------//
        //                                                                   //
        //                             Solutions                             //
        //                                                                   //
        //-------------------------------------------------------------------//
      }
      <NavItem widthLabel={102}>
        <label>
          Solutions
          <IconChevronUp />
        </label>
        <ul>
          <NavItemLi>
            <Link href={'/?solutions=payments'}>
              <a>
                <IconWallet />
                <NavItemLiContent>
                  <label>Payments</label>
                  <p>Great solution for shops and subscription-based services.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/?solutions=deposits'}>
              <a>
                <IconPayments />
                <NavItemLiContent>
                  <label>Deposits</label>
                  <p>
                    Solution for services with an internal balance, for example, gambling and online
                    games.
                  </p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/?solutions=invoices'}>
              <a>
                <IconRequest />
                <NavItemLiContent>
                  <label>Invoices</label>
                  <p>An excellent solution for flexible invoicing for goods or services.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/?solutions=whiteLabel'}>
              <a>
                <IconBag />
                <NavItemLiContent>
                  <label>White Label</label>
                  <p>
                    Solution to set your own tariffs
                    <br /> for customer service.
                  </p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          {/*<NavItemLi>*/}
          {/*  <a href={'#'}>*/}
          {/*    <IconAffiliate />*/}
          {/*    <NavItemLiContent>*/}
          {/*      <label>Affilate program</label>*/}
          {/*      <p>Best affiliate program in the crypto space.</p>*/}
          {/*    </NavItemLiContent>*/}
          {/*  </a>*/}
          {/*</NavItemLi>*/}
        </ul>
      </NavItem>
      {
        //-------------------------------------------------------------------//
        //                                                                   //
        //                            Help Center                            //
        //                                                                   //
        //-------------------------------------------------------------------//
      }
      <NavItem widthLabel={124}>
        <label>
          Help Center
          <IconChevronUp />
        </label>
        <ul>
          <NavItemLi>
            <Link href={'/guide'}>
              <a>
                <IconGuide />
                <NavItemLiContent>
                  <label>Guide</label>
                  <p>Uncovering the benefits of exploring.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/#faq'}>
              <a>
                <IconFaq />
                <NavItemLiContent>
                  <label>FAQ</label>
                  <p>Answers to the most popular questions.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <a href={'https://docs.usegateway.net/'} target={'_blank'} rel="noreferrer">
              <IconApi />
              <NavItemLiContent>
                <label>API</label>
                <p>For fast and convenient integration.</p>
              </NavItemLiContent>
            </a>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/status'}>
              <a>
                <IconToggle />
                <NavItemLiContent>
                  <label>System status</label>
                  <p>Transparency around service availability.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>
        </ul>
      </NavItem>
      {
        //-------------------------------------------------------------------//
        //                                                                   //
        //                              About us                             //
        //                                                                   //
        //-------------------------------------------------------------------//
      }
      <NavItem widthLabel={100}>
        <label>
          About us
          <IconChevronUp />
        </label>
        <ul>
          <NavItemLi>
            <a onClick={toggleWidget}>
              <IconNews />
              <NavItemLiContent>
                <label>
                  What’s new
                  {!!newsCount && <NewsCount>{newsCount > 99 ? '99+' : newsCount}</NewsCount>}
                </label>
                <p>Stay Up-to-Date.</p>
              </NavItemLiContent>
            </a>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/#testimonials'}>
              <a>
                <IconTestimonials />
                <NavItemLiContent>
                  <label>Testimonials</label>
                  <p>Review, recommendation from clients.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <Link href={'/#social'}>
              <a>
                <IconMessage />
                <NavItemLiContent>
                  <label>Social media</label>
                  <p>Join us on social networks.</p>
                </NavItemLiContent>
              </a>
            </Link>
          </NavItemLi>

          <NavItemLi>
            <a href="mailto:support@usegateway.group">
              <IconRequestMenu />
              <NavItemLiContent>
                <label>Submit a request</label>
                <p>Convenient way to communicate with customer support.</p>
              </NavItemLiContent>
            </a>
          </NavItemLi>
        </ul>
      </NavItem>
      {
        //-------------------------------------------------------------------//
        //                                                                   //
        //                             Contact us                            //
        //                                                                   //
        //-------------------------------------------------------------------//
      }
      <Link href={'/#contact'}>
        <NavLink width={87}>Contact us</NavLink>
      </Link>
    </NavWrapper>
  );
}
