import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import { getRegisterUrl, useSsrDetector } from '@use-gateway/utils';
import {
  SolutionContent,
  SolutionDescription,
  SolutionLink,
  SolutionText,
  SolutionTitle,
  SolutionWrapper,
} from './styles';
import img from '../../assets/img/solutions/deposits/deposits.png';
import imgX2 from '../../assets/img/solutions/deposits/deposits@2x.png';
import imgX2Webp from '../../assets/img/solutions/deposits/deposits@2x.webp';

const SectionImg = styled.div`
  display: flex;
  justify-content: center;
  max-width: 550px;

  ${down('md')} {
    width: fit-content;
    margin: 0 auto 36px;
  }

  img {
    width: 100%;
    margin: -50px -66px -70px -50px;

    ${down('md')} {
      max-width: 286px;
      margin: -28px auto -42px;
    }
  }
`;

interface SolutionDepositProps {
  isActive: boolean;
}

export function SolutionDeposit({ isActive }: SolutionDepositProps) {
  const { isDown, isUp } = useBreakpoints();
  const ssr = useSsrDetector();

  if (ssr) return null;

  return (
    <SolutionWrapper isActive={isActive}>
      <SolutionContent>
        <SolutionText>
          <SolutionTitle>
            Deposits are the perfect solution for topping up the balance
          </SolutionTitle>
          <SolutionDescription>
            Deposits are a solution designed to top up the internal balance by the amount chosen by
            the customer. The seller can set the smallest and the biggest amount to top up.
          </SolutionDescription>
        </SolutionText>
        {isDown(Breakpoints.md) && (
          <SectionImg>
            <picture>
              <source type="image/webp" srcSet={imgX2Webp.src} />
              <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
            </picture>
          </SectionImg>
        )}
        <SolutionLink href={getRegisterUrl()}>
          <Button>Get Started</Button>
        </SolutionLink>
      </SolutionContent>
      {isUp(Breakpoints.md) && (
        <SectionImg>
          <picture>
            <source type="image/webp" srcSet={imgX2Webp.src} />
            <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
          </picture>
        </SectionImg>
      )}
    </SolutionWrapper>
  );
}
