/* eslint-disable import/order */
/* eslint-disable @next/next/no-img-element */
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import clearInterface from '../assets/img/interfaces/clear-interface.png';
import clearInterfaceX2 from '../assets/img/interfaces/clear-interface@2x.png';
import clearInterfaceX2Webp from '../assets/img/interfaces/clear-interface@2x.webp';
import clearInterfaceMob from '../assets/img/interfaces/clear-interface-m.png';
import clearInterfaceMobX2 from '../assets/img/interfaces/clear-interface-m@2x.png';
import clearInterfaceMobX2Webp from '../assets/img/interfaces/clear-interface-m@2x.webp';
import { useIntersectionObserver, useSsrDetector } from '@use-gateway/utils';

const InterfacesWrapper = styled.section`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 48px 0 36px;
  background-color: ${({ theme }) => theme.colors.info[100]};
`;

const InterfacesContent = styled.div`
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    ${down('md')} {
      width: 274px;
      height: 594px;
    }
  }
`;

const InterfacesTitle = styled.h2`
  margin: 0 0 32px;
  font-weight: 600;
  font-size: 32px;
  color: #fff;

  ${down('md')} {
    text-align: center;
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 54px;
  }
`;

export function Interfaces() {
  const { isDown } = useBreakpoints();
  const ssr = useSsrDetector();
  const { ref, isVisible } = useIntersectionObserver(ssr);

  if (ssr) return null;

  return (
    <InterfacesWrapper>
      <InterfacesContent
        ref={ref}
        className={isVisible ? 'animation-block active' : 'animation-block'}>
        <InterfacesTitle>Simple clear interface</InterfacesTitle>
        {isDown(Breakpoints.md) ? (
          <picture>
            <source type="image/webp" srcSet={clearInterfaceMobX2Webp.src} />
            <img
              src={clearInterfaceMob.src}
              srcSet={`${clearInterfaceMob.src}, ${clearInterfaceMobX2.src} 2x`}
              alt=""
            />
          </picture>
        ) : (
          <picture>
            <source type="image/webp" srcSet={clearInterfaceX2Webp.src} />
            <img
              src={clearInterface.src}
              srcSet={`${clearInterface.src}, ${clearInterfaceX2.src} 2x`}
              alt=""
            />
          </picture>
        )}
      </InterfacesContent>
    </InterfacesWrapper>
  );
}
