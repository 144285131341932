import { useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { QuestionModel, Questions } from '@use-gateway/components';
import { useIntersectionObserver } from '@use-gateway/utils';

const FaqWrapper = styled.section`
  position: relative;
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;
  padding: 68px 0 96px;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  ${down('md')} {
    min-height: auto;
    padding: 30px 0;
  }

  h2 {
    width: fit-content;
    margin: 0 auto 96px;
    font-weight: 600;
    font-size: 32px;

    ${down('md')} {
      margin-bottom: 42px;
      font-size: 24px;
    }
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: 0;

  ${down('md')} {
    margin-top: -77px;
  }
`;

export function Faq() {
  const { ref, isVisible } = useIntersectionObserver();

  const [questions, setQuestions] = useState<QuestionModel[]>([
    {
      id: 1,
      isOpen: false,
      question: 'How to start receiving payments in crypto assets form using your service?',
      answer: `
        <p>
          First thing to do is to register an account on our website. Then you’ll have to decide 
          on how you are planning to integrate UseGateway to your business model, since numerous
          ways are available for our client’s convenience:
        </p>
        <ol>
          <li>
            <b>Redirecting customers to the UseGateway website for payment or deposit replenishment.</b>
            This method requires minimal API integration, which makes it great for any websites,
            payment bots on social networks and etc.
          </li>
          <li>
            You may create your own payment form by <b>utilizing UseGateway API</b>, which will allow you
            to harmoniously integrate our payment interface into your website design and business logics.
          </li>
          <li>
            <b>Manual payment execution.</b> This method doesn’t require any integration. Right after the 
            registration on our website you may perform payments through your personal account. In order 
            to do this, simply click on the ‘Transactions’ tab and choose ‘Create payment’ option and then
            send the resulting link to your client.
          </li>
        </ol>
        `,
    },
    {
      id: 2,
      isOpen: false,
      question: 'What’s UseGateway commission rate?',
      answer: `
        <p>
          The amount of our fee depends on the monthly amount of payments. You can find a table
          of fees for using UseGateway below.
        </p>
        <table>
          <thead>
            <tr>
              <th align="right">Amount / month</th>
              <th>Fee (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="right">$0 - $150 000</td>
              <td>
                <span>1</span>
              </td>
            </tr>
             <tr>
              <td align="right">$150 000 - $500 000</td>
              <td>
                <span>0,8</span>
              </td>
            </tr>
             <tr>
              <td align="right">$500 000 - $1 000 000</td>
               <td>
                <span>0,6</span>
              </td>
            </tr>
             <tr>
              <td align="right">$1 000 000 +</td>
              <td>
                <span>0,4</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Our fees do not include blockchain fees. The blockchain fee and the UseGateway service
          fee are charged when you withdraw funds. 
        </p>
        <h4>Attention!</h4>
        <p>
          If you registered via a link from our partners, you may be charged a custom fee that
          differs from those indicated in the table. The partners set the fee that is applied 
          if you registered via their link, and we cannot change it. It can be either less or 
          more than those indicated in the table.
        </p>
      `,
    },
    {
      id: 3,
      isOpen: false,
      question: 'Which crypto assets (tokens or cryptocurrencies) does UseGateway work with?',
      answer: `
        <p>
          We currently allow our users to receive payments using the following crypto assets:
          <br />
          Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC), TRON (TRX), BNB Smart Chain (BNB),
          Tether (USDT TRC20, BEP20, ERC20), Binance USD (BUSD).
        </p>
      `,
    },
    {
      id: 4,
      isOpen: false,
      question: 'Do you have any KYC monitoring ongoing? ',
      answer: `
        <p>
          No. Since we are not a financial services provider and do not have any access to the
          funds on your wallets. We do only provide software solution, which allows our customers
          to accept various crypto assets in a timely and safe manner.
        </p>
      `,
    },
    {
      id: 5,
      isOpen: false,
      question: 'What is the difference between payments and deposits?',
      answer: `
        <p>
          Payment is a tool for online stores, where products or services are supplied at fixed price.
          This tool can be handy for replenishing the balance on a fixed amount (for example, $ 50/100/150).
        </p>
        <p>
          The customer is being billed at the moment of payment creation. Exchange rate is fixed for 60 minutes,
          during which the client should perform a payment. 60 minutes is the default value which could be changed
          in the settings section of your personal account on the UseGateway website.
        </p>
        <p>
          Unlike payments, deposits involve automatic creation of a new wallet for each of your clients,
          which a client is allowed to replenish at any time for any amount exceeding the minimum replenishment
          amount you set.
        </p>
        <p>
          Deposit is a solution that works best for charities, gaming services, poker rooms, casinos.
        </p>
      `,
    },
    {
      id: 6,
      isOpen: false,
      question: 'Do you have any limits for transactions amounts?',
      answer: `
        <p>
          We do not have any rules regarding this, but we strongly recommend setting the minimum payment amount
          to be no less than 5$, since otherwise the blockchain network commission (charged as the miner’s or
          validator’s reward) can eat up a significant part of the transferred amount. Neither we nor you can
          influence this commission rate.
        </p>
      `,
    },
    {
      id: 7,
      isOpen: false,
      question: 'Are you open for cooperation?',
      answer: `
        Yes, we are actively working with partners, particularly on joint promotions, integration with other
        payment services and platforms, opening representative offices in other regions. For any questions related
        to establishing partnership, please contact us using email -
        <a href="mailto:support@usegateway.group">support@usegateway.group</a> or Telegram - 
        <a href="https://t.me/usegateway" target="_blank">@usegateway</a>.
      `,
    },
    {
      id: 8,
      isOpen: false,
      question: 'Do you have any rules or restrictions for transactions?',
      answer: `
        <p>
          Payments for selling drugs, medicines (in case you don’t have official rights to distribute them)
          are not allowed. This is also applicable to fraud related transactions and collecting donations
          for military purposes.
        </p>
      `,
    },
  ]);

  return (
    <FaqWrapper>
      <Anchor id={'faq'} />
      <div ref={ref} className={isVisible ? 'animation-block active' : 'animation-block'}>
        <h2>FAQ</h2>
        <Questions questions={questions} setQuestions={setQuestions} />
      </div>
    </FaqWrapper>
  );
}
