import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import { getRegisterUrl, useSsrDetector } from '@use-gateway/utils';
import {
  SolutionContent,
  SolutionDescription,
  SolutionLink,
  SolutionText,
  SolutionTitle,
  SolutionWrapper,
} from './styles';
import imgMob from '../../assets/img/solutions/payments/payments-m.png';
import imgMobX2 from '../../assets/img/solutions/payments/payments-m@2x.png';
import imgMobX2Webp from '../../assets/img/solutions/payments/payments-m@2x.webp';
import img from '../../assets/img/solutions/payments/payments.png';
import imgX2 from '../../assets/img/solutions/payments/payments@2x.png';
import imgX2Webp from '../../assets/img/solutions/payments/payments@2x.webp';

const SectionImg = styled.div`
  box-sizing: border-box;
  width: 100%;

  ${down('md')} {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 0 auto 36px;
  }

  img {
    max-width: 100%;
    margin: 0 auto;
    border-radius: 16px;
    filter: drop-shadow(8.40235px 8.40235px 60px rgba(0, 0, 0, 0.15));

    ${down('md')} {
      width: 260px;
      margin-top: -33px;
      margin-bottom: -47px;
      filter: none;
    }
  }
`;

interface SolutionPaymentsProps {
  isActive: boolean;
}

export function SolutionPayments({ isActive }: SolutionPaymentsProps) {
  const { isDown, isUp } = useBreakpoints();
  const ssr = useSsrDetector();

  if (ssr) return null;

  return (
    <SolutionWrapper isActive={isActive}>
      <SolutionContent>
        <SolutionText>
          <SolutionTitle>Cryptocurrency payments for your business</SolutionTitle>
          <SolutionDescription>
            Payment is a solution for products or services with an exactly defined value. The buyer
            can see the exact amount they need to pay and makes a payment in the selected
            cryptocurrency.
          </SolutionDescription>
        </SolutionText>
        {isDown(Breakpoints.md) && (
          <SectionImg>
            <picture>
              <source type="image/webp" srcSet={imgMobX2Webp.src} />
              <img src={imgMob.src} srcSet={`${imgMob.src}, ${imgMobX2.src} 2x`} alt="" />
            </picture>
          </SectionImg>
        )}
        <SolutionLink href={getRegisterUrl()}>
          <Button>Get Started</Button>
        </SolutionLink>
      </SolutionContent>
      {isUp(Breakpoints.md) && (
        <SectionImg>
          <picture>
            <source type="image/webp" srcSet={imgX2Webp.src} />
            <img src={img.src} srcSet={`${img.src}, ${imgX2.src} 2x`} alt="" />
          </picture>
        </SectionImg>
      )}
    </SolutionWrapper>
  );
}
