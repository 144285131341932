import { useEffect, useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Button } from '@use-gateway/components';
import {
  IconApi,
  IconBag,
  IconChevronUp,
  IconFaq,
  IconGuide,
  IconMessage,
  IconNews,
  IconPayments,
  IconRequest,
  IconRequestMenu,
  IconTestimonials,
  IconToggle,
  IconWallet,
} from '@use-gateway/icons';
import { getLoginUrl, useNoticeable } from '@use-gateway/utils';

interface MenuWrapperProps {
  isMenu: boolean;
}

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: fixed;
  box-sizing: border-box;
  overflow: auto;
  z-index: 999;
  top: ${({ isMenu }) => (isMenu ? '78px' : '-100%')};
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 78px);
  padding: 36px 16px;
  background-color: #fff;
  transition: 0.3s;
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;

  a {
    width: 100%;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    line-height: 1.38;
    color: ${({ theme }) => theme.colors.dark[100]};
    text-decoration: none;
  }
`;

const Item = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 36px;
  padding: 0 36px;
`;

interface ItemsTitleProps {
  isActive: boolean;
}

const Items = styled.div<ItemsTitleProps>`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 36px;
  padding: 0 36px;

  & > label {
    display: block;
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;

    svg {
      min-width: 16px;
      margin-left: 8px;
      margin-bottom: -2px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.info[100]};
      transform: ${({ isActive }) => (isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
    }
  }

  ul {
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    margin: 36px 0 0;
    padding-left: 0;
  }
`;

const Li = styled.li`
  padding-left: 36px;
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  a {
    display: flex;
    gap: 16px;
  }

  svg {
    min-width: 24px;
    font-size: 24px;
  }
`;

const LiContent = styled.div`
  label {
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 16px;
  }

  p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.light[40]};
  }
`;

const NewsCount = styled.span`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 10px;
  line-height: 1;
  color: #fff;
`;

interface MenuProps {
  isMenu: boolean;
  setIsMenu: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

enum MenuItem {
  Solutions = 'Solutions',
  HelpCenter = 'HelpCenter',
  AboutUs = 'AboutUs',
}

export function Menu({ isMenu, setIsMenu }: MenuProps) {
  const [openItem, setOpenItem] = useState<MenuItem | null>(null);
  const { newsCount, toggleWidget } = useNoticeable();

  const closeMenu = () => {
    setIsMenu(false);
  };

  useEffect(() => {
    if (isMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      setOpenItem(null);
    }
  }, [isMenu]);

  return (
    <MenuWrapper isMenu={isMenu}>
      <MenuList>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                             Solutions                             //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Items
          isActive={openItem === MenuItem.Solutions}
          onClick={() =>
            openItem === MenuItem.Solutions ? setOpenItem(null) : setOpenItem(MenuItem.Solutions)
          }>
          <label>
            Solutions
            <IconChevronUp />
          </label>
          <ul>
            <Li>
              <Link href={'/?solutions=payments'}>
                <a onClick={closeMenu}>
                  <IconWallet />
                  <LiContent>
                    <label>Payments</label>
                    <p>Great solution for shops and subscription-based services.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <Link href={'/?solutions=deposits'}>
                <a onClick={closeMenu}>
                  <IconPayments />
                  <LiContent>
                    <label>Deposits</label>
                    <p>
                      Solution for services with an internal balance, for example, gambling and
                      online games.
                    </p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <Link href={'/?solutions=invoices'}>
                <a onClick={closeMenu}>
                  <IconRequest />
                  <LiContent>
                    <label>Invoices</label>
                    <p>An excellent solution for flexible invoicing for goods or services.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <Link href={'/?solutions=whiteLabel'}>
                <a onClick={closeMenu}>
                  <IconBag />
                  <LiContent>
                    <label>White Label</label>
                    <p>Solution to set your own tariffs for customer service.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>
          </ul>
        </Items>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                            Help Center                            //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Items
          isActive={openItem === MenuItem.HelpCenter}
          onClick={() =>
            openItem === MenuItem.HelpCenter ? setOpenItem(null) : setOpenItem(MenuItem.HelpCenter)
          }>
          <label>
            Help Center
            <IconChevronUp />
          </label>

          <ul>
            <Li>
              <Link href={'/guide'}>
                <a onClick={closeMenu}>
                  <IconGuide />
                  <LiContent>
                    <label>Guide</label>
                    <p>Uncovering the benefits of exploring.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <Link href={'/#faq'}>
                <a onClick={closeMenu}>
                  <IconFaq />
                  <LiContent>
                    <label>FAQ</label>
                    <p>Answers to the most popular questions.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <a
                href={'https://docs.usegateway.net/'}
                target={'_blank'}
                rel="noreferrer"
                onClick={closeMenu}>
                <IconApi />
                <LiContent>
                  <label>API</label>
                  <p>For fast and convenient integration.</p>
                </LiContent>
              </a>
            </Li>

            <Li>
              <Link href={'/status'}>
                <a onClick={closeMenu}>
                  <IconToggle />
                  <LiContent>
                    <label>System status</label>
                    <p>Transparency around service availability.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>
          </ul>
        </Items>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                              About us                             //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Items
          isActive={openItem === MenuItem.AboutUs}
          onClick={() =>
            openItem === MenuItem.AboutUs ? setOpenItem(null) : setOpenItem(MenuItem.AboutUs)
          }>
          <label>
            About us
            <IconChevronUp />
          </label>
          <ul>
            <Li>
              <a
                onClick={() => {
                  toggleWidget();
                  closeMenu();
                }}>
                <IconNews />
                <LiContent>
                  <label>
                    What’s new
                    {!!newsCount && <NewsCount>{newsCount > 99 ? '99+' : newsCount}</NewsCount>}
                  </label>
                  <p>Stay Up-to-Date.</p>
                </LiContent>
              </a>
            </Li>

            <Li>
              <Link href={'/#testimonials'}>
                <a onClick={closeMenu}>
                  <IconTestimonials />
                  <LiContent>
                    <label>Testimonials</label>
                    <p>Review, recommendation from clients.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <Link href={'/#social'}>
                <a onClick={closeMenu}>
                  <IconMessage />
                  <LiContent>
                    <label>Social media</label>
                    <p>Join us on social networks.</p>
                  </LiContent>
                </a>
              </Link>
            </Li>

            <Li>
              <a href={'mailto:support@usegateway.group'} onClick={closeMenu}>
                <IconRequestMenu />
                <LiContent>
                  <label>Submit a request</label>
                  <p>Convenient way to communicate with customer support.</p>
                </LiContent>
              </a>
            </Li>
          </ul>
        </Items>
        {
          //-------------------------------------------------------------------//
          //                                                                   //
          //                             Contact us                            //
          //                                                                   //
          //-------------------------------------------------------------------//
        }
        <Item onClick={closeMenu}>
          <Link href={'/#contact'}>Contact us</Link>
        </Item>

        <a onClick={closeMenu} href={getLoginUrl()}>
          <Button variant={'outline'}>Sign in</Button>
        </a>
      </MenuList>
    </MenuWrapper>
  );
}
