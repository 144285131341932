import { down, up } from 'styled-breakpoints';
import styled from 'styled-components';

export const LayoutLandingWrapper = styled.div`
  ${down('md')} {
    padding-top: 78px;
  }

  .animation-block {
    ${up('md')} {
      opacity: 0;
      transform: translateY(100px);

      &.active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 1s ease-out, transform 1s ease-out;
      }
    }
  }
`;
