import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { useLanding } from '@use-gateway/utils';
import { CardCreateWallet } from './card-create-wallet';

const GuideContentWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 502px;
  margin: 0 auto;
  padding: 0 16px;
  font-family: 'Nunito', sans-serif;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.16;
  }
`;

const Contents = styled.section`
  margin-top: 94px;
  margin-bottom: 90px;

  ${down('md')} {
    margin-top: 118px;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 24px;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 4px;

      a {
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.info[100]};

        &:hover {
          border-bottom: 1px currentColor dotted;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Section = styled.section`
  padding: 24px 0;

  ${down('sm')} {
    margin-bottom: -42px;
    padding: 90px 0 0;
  }

  &:last-child {
    padding-bottom: 42px;
  }

  span {
    color: ${({ theme }) => theme.colors.info[100]};

    &.progress {
      font-weight: 700;
      color: #ff8b20;
    }

    &.completed {
      font-weight: 700;
      color: #61bd4a;
    }

    &.error {
      font-weight: 700;
      color: #e51212;
    }
  }

  b {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.info[100]};
  }

  strong {
    font-weight: 700;
  }

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    padding-left: 20px;
    font-size: 16px;
    line-height: 1.25;
    color: ${({ theme }) => theme.colors.dark[100]};
  }
`;

export function GuideContent() {
  const { notification } = useLanding();

  return (
    <GuideContentWrapper>
      {notification && <CardCreateWallet />}
      <Contents>
        <h2>Contents</h2>
        <ul>
          <li>
            <a href={'#intro'}>Intro</a>
          </li>
          <li>
            <a href={'#crypto-assets'}>Crypto assets available for reception by UseGateway</a>
          </li>
          <li>
            <a href={'#commissions'}>Commissions and fees</a>
          </li>
          <li>
            <a href={'#balance'}>Balance and wallet</a>
          </li>
          <li>
            <a href={'#interactions'}>Interactions and notifications</a>
          </li>
          <li>
            <a href={'#getting'}>Getting started</a>
          </li>
          <li>
            <a href={'#payments'}>Payments</a>
          </li>
          <li>
            <a href={'#deposits'}>Deposits</a>
          </li>
          <li>
            <a href={'#integration'}>Integration</a>
          </li>
          <li>
            <a href={'#exchange'}>Exchange rates</a>
          </li>
          <li>
            <a href={'#withdrawal'}>Withdrawal using API (Withdrawal Request)</a>
          </li>
        </ul>
      </Contents>
      <Section id={'intro'}>
        <h2>Intro</h2>
        <p>
          The main purpose of this paper is to provide a detailed review of the UseGateway payment
          service in order to form a clear understanding of its capabilities for addressing business
          challenges.
        </p>
        <p>
          UseGateway allows business to satisfy two essential needs: to receive payments from
          clients for goods or services and to top up clients account (in case of an entertainment
          service or casino, for example).
        </p>
        <p>
          UseGateway stands apart from the usual payment acceptance services. Its differences are
          caused by peculiar properties of crypto-currencies as working with those assets differs
          from working with fiat currencies (like US dollars, EUR, Tenge, Manats, Hryvnias, Rubles
          and etc.)
        </p>
        <p>
          <span>
            The first distinction is a period between the initiation and completion of the transfer.
          </span>{' '}
          In crypto-currencies case payment time may vary from several minutes to even multiple
          hours depending on chosen crypto-currency, payment executor (miner) commission and the
          network load. These conditions cannot be changed by UseGateway.
        </p>
        <p>
          <span>The second distinction is an unstable exchange rate.</span> Normally, businesses
          perform transactions using fiat currencies, as the crypto exchange rate may severely
          fluctuate even In term of hours.
        </p>
        <p>
          <span>The third one is about internal differences of used cryptocurrencies.</span> –
          Despite the fact that within the course of this review we refer to all crypto assets as
          crypto-currencies in order to keep the things simple, these assets are architecturally
          pretty different from one another. Each of these instruments has its own unique
          characteristics such as: transaction execution speed, exchange rate stability, amount of
          commission for transactions, method of paying the commission and so on.
        </p>
      </Section>
      <Section id={'crypto-assets'}>
        <h2>Crypto assets available for reception by UseGateway.</h2>
        <p>
          Service allows to receive payments from users in the form of the following crypto assets:
          BTC, ETH, LTC, TRON (TRX), Tether (USDT TRC20).
        </p>
        <p>
          Tether (USDT TRC20) - is a so called stable-coin, as its exchange rate is normally close
          the US dollar one’s and remains stable during long periods of time.
        </p>
      </Section>
      <Section id={'commissions'}>
        <h2>Commissions and fees</h2>
        <p>
          There is a commission charged by the crypto-network and it depends exclusively on the
          cryptocurrency and its terms and conditions of usage and there is also a fee charged by
          UseGateway for its services.
        </p>
        <p>Fee is charged at the moment of funds withdrawal or payment refund.</p>
        <p>
          The commission of the UseGateway service cannot be less than the minimum transfer allowed
          on the selected network.
        </p>
        <p>
          Minimal amounts accepted for transfer are:
          <br />
          0.0001 Bitcoin
          <br />
          0.0001 Litecoin
          <br />
          0.000001 TRON
        </p>
        <p>
          Fee for transfers using USDT TRC20 is accepted in TRON (TRX) cryptocurrency. If your TRX
          account balance is insufficient you may manually create a payment in order to pay using
          TRX. Choose Create payment option on the Transactions tab and then use generated link to
          make payment.
        </p>
      </Section>
      <Section id={'balance'}>
        <h2>Balance and wallet</h2>
        <p>We will be using the following terms throughout the whole documentation:</p>
        <p>
          <b>Balance</b> – is the sum of all transactions, which is available for you to withdraw.
          Balance is displayed in the personal account on the UseGateway website. Please notice that
          the sum reflected doesn’t include withdrawal fee.
        </p>
        <p>
          <b>Wallet</b> – is a conditional name for an address which is created inside a chosen
          crypto-network (blockchain) like Bitcoin or Litecoin for execution of payments and topping
          up the deposit. For each payment a unique address is created and is further used for no
          other purpose, and in case of a deposit – individual addresses are being set up for the
          client.
        </p>
        <p>
          <b>Total balance</b> - is the amount of funds stored on all wallets, recalculated at the
          exchange rate of fiat currency specified in the settings section of personal account.
        </p>
        <p>
          Hereinafter funds received to all wallets are transferred to a shared wallet in this
          blockchain and are withdrawn to the client specified wallet excluding the UseGateway
          service fee.
        </p>
      </Section>
      <Section id={'interactions'}>
        <h2>Interactions and notifications.</h2>
        <p>
          We use Application programming interface (API) for data transmission and receival.
          Payments can be managed through UseGateway personal account.
        </p>
        <p>
          Notifications may be received by using Webhooks or by email, which may be configured in
          your UseGateway personal account.
        </p>
      </Section>
      <Section id={'getting'}>
        <h2>Getting started</h2>
        <div>
          <b>
            <ol>
              <li>Register on the UseGateway website.</li>
              <li>
                Create wallets by pressing <span>Create wallet</span> under the <span>Wallet</span>{' '}
                tab.
              </li>
              <li>Save the seed phrase.</li>
            </ol>
          </b>
        </div>
        <p>
          UseGateway operates in the way of non – custodial wallet, which allows you to retain full
          control other all your crypto assets. Please save the seed phrase to secure location, in
          case of its loss access to your funds will be permanently lost.
        </p>
        <p>
          We highly recommend to perform a withdrawal of funds after a first few payments in order
          to reconfirm the seed phrase.
        </p>
        <ol>
          <li>
            Generate an API key.
            <br /> Go to <strong>Settings</strong> {'>'} <strong>Security</strong> {'>'}{' '}
            <strong>Create new API key.</strong>
          </li>
          <li>
            Create a Webhook.
            <br /> Go to <strong>Settings</strong> {'>'} <strong>Notifications</strong> {'>'}{' '}
            <strong>Add endpoint.</strong>
          </li>
        </ol>
      </Section>
      <Section id={'payments'}>
        <h2>Payments</h2>
        <p>
          <b>Payment</b> is a tool suitable for online-stores, where goods and services are being
          sold at fixed cost. This tool can also be utilized for topping up account balance by fixed
          amounts (like 50/100/500$).
        </p>
        <p>
          In the course of payment creation, the customer is billed for payment. Exchange rate is
          fixed for 60 minutes from the creation moment, during which client should make a payment.
          Please note that 60 minutes is a default value which can be changed in the settings
          section of UseGateway personal account.
        </p>
        <p>
          Client may choose any crypto-currency which you specified as suitable for payments among
          all the options supported by Usegateway (BTC, ETH, LTC, TRON (TRX), USDT TRC20).
        </p>
        <p>
          You may receive information on payment status and its completion using{' '}
          <span>Webhook</span> or personal <span>account</span> section of the UseGateway website.
        </p>
        <p>
          In case of customer making payment after the expiration of payment period, the payment
          comes with <strong>Expired</strong> status.
        </p>
        <p>
          In this case, you should accept (resolve) or return (refund) the payment through your
          personal account or API. Paid amount will not be credited to your balance until the
          payment is resolved.
        </p>
        <p>
          De facto, UseGateway doesn’t have a deadline for a payment, and even in the case of paying
          after a year from creation date, the payment will be delivered to you with the Expired
          status.
        </p>
        <p>
          If the client pays less than the specified amount, the payment comes with the status -
          Underpaid.
        </p>
        <p>
          In this case, you should accept (resolve) or return (refund) the payment through your
          personal account or API. Paid amount will not be credited to your balance until the
          payment is resolved.
        </p>
        <p>
          If the customer pays more than the required amount, the payment is credited to your
          balance withthe <strong>Overpaid</strong> status. In case of normal payment within the
          specified amount in, the payment status is marked as <strong>Completed</strong>. Payments
          with the Overpaid and Completed statuses are automatically credited to your account and
          don’t require any additional actions.
        </p>
        <p>
          <strong>Multiple</strong> status is assigned to the re-payed payment.
        </p>
        <p>
          Regardless of the payment status, any transaction in UseGateway can be refunded. The
          blockchain commission for the reverse transfer is paid by the user. It can be deducted
          from the amount refunded to the client. Also, the UseGateway fee will be charged for
          reverse operation.
        </p>
        <p>
          Before performing the refund procedure, it is recommended to negotiate with the client
          whether he is ready to accept the transfer to the wallet address which was used for
          initial payment, which is not possible in some cases.
        </p>
      </Section>
      <Section id={'deposits'}>
        <h2>Deposits</h2>
        <p>
          Unlike a payment, a deposit involves automated creation of wallets for each of your
          clients, which the client is able to replenish at any time for any amount exceeding the
          minimum transaction amount specified by the user.
        </p>
        <p>
          Setting the minimum amount of replenishment is not only able to cope with your business
          objectives, but can also serve as a safeguard to prevent situations when you have to pay a
          significant part from the client&apos;s transaction as a network commission.
        </p>
        <p>
          For example, at the moment of preparing this material, the minimum amount of the network
          commission for transferring USDT TRC20 was approximately 1USD. If a USDT wallet is created
          for the client and the client deposits only 1USD during the wallet lifetime, then in case
          ofan attempt to withdraw funds, the withdrawal commission will be approximately same as
          the wallet balance. We do recommend setting a minimum deposit amount equivalent to 3USD or
          higher.
        </p>
        <p>
          With the help of Webhooks, you will receive notifications on the transfers to the wallet
          and will be able to replenish the client&apos;s deposit in your service for the
          corresponding amount. You will receive information about the transaction in terms of your
          chosen fiat currency at the exchange rate fixed in the moment of actual transfer of funds
          wallet.
        </p>
        <p>
          If the user sends an amount less than the minimum specified amount, the deposit
          replenishment will have the Underpaid status. In this case, through your personal account
          or API, you should accept the replenishment and transfer the funds that have come up
          (resolve) or return them (refund). The payment amount will not be credited to your balance
          until resolve.
        </p>
        <p>
          Payments credited to your account balance have a <strong>Replenished</strong> status.
        </p>
        <p>
          To create a UseGateway deposit, it is essential to receive the name of the site or service
          and the user ID from the client. The site is important in order to avoid confusion if
          you’ll have several projects connected and the user IDs happen to match there. You can
          specify any value in the site field by which UseGateway will be able to distinguish your
          projects.
        </p>
        <p>
          Any unique client identifier available to your service can be used as a User ID. We don’t
          recommend using any personal data of the client, such as email or phone number. First of
          all, such identifiers may change while for UseGateway, new user ID will result in new
          wallets creation. Secondly, additional risk of personal data leakage exists, which can be
          easily avoided by using impersonated user ID as well as a unique username.
        </p>
        <p>
          Deposit is a solution which works great for charities, gaming services, poker rooms,
          casinos.
        </p>
      </Section>
      <Section id={'integration'}>
        <h2>Integration</h2>
        <div>
          There are <span>three ways for integration</span> of UseGateway into your business model:
          <ol>
            <li>
              <span>Client redirection to the UseGateway website.</span> This method requires
              minimal integration. Its possible to customize the payment form by adding links for
              redirecting client in case of a successful or unsuccessful transfer.Integration method
              described above suits pretty well for any websites, bots or payments through social
              networks.
            </li>
            <li>
              To implement a <span>ready-made form</span> for payment or adding funds to user
              account on your website so the client will not have to leave your it in order to
              perform a payment operation.
            </li>
            <li>
              You may also create <span>a custom payment form using our API</span>, which will
              perfectly fit in your website’s design and business logic.
            </li>
            <li>
              <span>Manual payment execution.</span> This mean requires no integration. Shortly
              registration you’ll be able to make payment through your personal account webpage. In
              order to perform this – simply click ‘Create payment’ button on the Transactions tab
              and then simply send a hyperlink to your customer.
            </li>
          </ol>
        </div>
      </Section>
      <Section id={'exchange'}>
        <h2>Exchange rates</h2>
        <p>
          API UseGateway API allows to receive current exchange rates for pairs like
          cryptocurrency/fiat currency and vice versa.
        </p>
        <p>Supported cryptocurrencies: BTC, ETH, LTC, TRX, USDT_TRC20.</p>
        <p>Supported fiat currencies: USD, EUR, UAH, RUB, KZT, AZN, TRY.</p>
        <p>
          Please note that there is no central regulatory body which determines the exchange rate
          for any cryptocurrency. Applied rate might vary slightly from one Crypto-exchange to
          another. These differences are normally being leveled over time by individuals or
          organizations involved in cryptocurrency arbitrage and making profits on the difference in
          exchange rates on different platforms.
        </p>
        <p>
          Thus, rates applied by UseGateway may insignificantly differ from ones, obtained from
          other sources.
        </p>
      </Section>
      <Section>
        <h2>Funds withdrawal</h2>
        <p>
          You may withdraw the earned funds to any wallet on the same crypto-network, for example,
          you can withdraw your Bitcoins to addresses on the Bitcoin network.
        </p>
        <p>
          You will be charged with UseGateway fee as well as with crypto-network commission at the
          moment of withdrawal. Any amount may be specified for withdrawal but in order to complete
          the requests, this amount should be available on your account along with required
          commission and fee amounts.
        </p>
        <p>
          Correct Seed Phrase is required to initiate a withdrawal request. After the request
          execution, funds will be sent to the specified address on the crypto-network. Crediting to
          your address depends on the operating speed of the chosen network and usually takes from 1
          to 15 minutes.
        </p>
        <p>
          Withdrawal requests history can be found in the Wallet section of personal account.
          Requests may have the following statuses:
        </p>
        <p>
          <span className={'progress'}>In progress</span> – withdrawal request was successfully
          created.
        </p>
        <p>
          <span className={'completed'}>Completed</span> - withdrawal request was successfully
          executed.
        </p>
        <p>
          <strong>Rejected</strong> – withdrawal request was denied by the system.
        </p>
        <p>
          <span className={'error'}>Error</span> – and error occurred during the process of
          withdrawal.
        </p>
      </Section>
      <Section id={'withdrawal'}>
        <h2>Withdrawal using API (Withdrawal Request)</h2>
        <p>
          You may also automatically create withdrawal requests, which will then only need to be
          confirmed in the UseGateway personal account by entering a seed phrase. While submitting a
          request desired cryptocurrency, wallet and a comment might be you specified.
        </p>
        <p>
          New requests will appear in the <b>Requests</b> section of personal account. User will be
          able to execute the request (<strong>Pay</strong> option) – in this case transfer from the
          balance of UseGateway wallet will be proposed; mark the requests as paid, without
          executing the payment (<strong>Approve</strong> option) or reject the request (
          <strong>Decline</strong> option).
        </p>
        <p>
          A request can have one of the following statuses respectively: <span>Received</span>{' '}
          (pending decision), <span>Approved</span> (after the applying <strong>Pay</strong> or{' '}
          <strong>Approve</strong> options) , <span>Declined</span> (in case of rejecting by
          choosing <strong>Decline</strong> option).
        </p>
      </Section>
    </GuideContentWrapper>
  );
}
