/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';
import Link from 'next/link';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Logo, Status, StatusColor, TrustBox } from '@use-gateway/components';
import { IconEmail2, IconTelegram } from '@use-gateway/icons';
import { CommonEvents } from '@use-gateway/types';
import { useEventBus, useNoticeable, useSsrDetector, useSystemStatus } from '@use-gateway/utils';
import chevronRight from '../assets/icons/chevron-right.svg';

const FooterWrapper = styled.footer`
  width: 100%;
  box-shadow: 0px 12px 98px rgba(0, 0, 0, 0.06);
`;

const FooterContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;
  padding: 38px 0 8px;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  ${down('md')} {
    padding: 24px 16px;
  }
`;

const FooterMain = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  ${down('md')} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-right: 96px;
  font-size: 0;

  img {
    max-width: 186px;
  }

  ${down('md')} {
    margin-right: 0;
  }
`;

const FooterColumns = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;

  ${down('md')} {
    flex-direction: column;
    align-items: center;
  }
`;

interface FooterColumnProps {
  active?: boolean;
}

const FooterColumn = styled.div<FooterColumnProps>`
  width: 80px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${down('md')} {
    width: 100%;
    max-width: 310px;
  }

  &:last-child {
    width: fit-content;

    ${down('md')} {
      width: 100%;
    }
  }

  label {
    margin-bottom: 16px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.light[40]};

    ${down('md')} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      margin-bottom: 30px;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 16px;
        background-image: url(${chevronRight});
        transform: ${({ active }) => (active ? 'rotate(90deg)' : 'rotate(0deg)')};
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;

    ${down('md')} {
      display: ${({ active }) => (active ? 'block' : 'none')};
      margin-bottom: 30px;
      font-size: 18px;
    }

    li:not(:last-child) {
      margin-bottom: 4px;

      ${down('md')} {
        margin-bottom: 8px;
      }
    }
  }

  span,
  a {
    color: ${({ theme }) => theme.colors.dark[100]};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      border-bottom: 1px currentColor dotted;
      color: ${({ theme }) => theme.colors.info[100]};
    }
  }
`;

const Support = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;

  a:hover {
    border-bottom: none;
  }

  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.dark[40]};

    &:hover {
      color: ${({ theme }) => theme.colors.info[100]};
    }
  }
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${down('md')} {
    justify-content: center;
  }
`;

const About = styled.p`
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.light[40]};
  font-size: 12px;
  line-height: 1.67;
`;

const StatusCircleWrapper = styled.span`
  display: inline-flex;
  position: relative;

  & > * {
    width: 1em;
    height: 1em;

    &:before {
      top: unset;
      bottom: 0;
      left: 0.35em;
      right: 0;
      transform: none;
    }
  }
`;

enum ActiveList {
  none = 'none',
  solutions = 'solutions',
  help = 'help',
  about = 'about',
  policies = 'policies',
  support = 'support',
}

export function FooterLanding() {
  const { emit } = useEventBus();
  const { statusLevel } = useSystemStatus();
  const { toggleWidget } = useNoticeable();
  const ssr = useSsrDetector();

  const [activeList, setActiveList] = useState(ActiveList.none);

  const handleActiveList = (column: ActiveList) => {
    activeList === column ? setActiveList(ActiveList.none) : setActiveList(column);
  };

  if (ssr) return null;

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterMain>
          <FooterLogo>
            <Logo />
            <TrustBox />
          </FooterLogo>

          <FooterColumns>
            <FooterColumn
              active={activeList === ActiveList.solutions}
              onClick={() => handleActiveList(ActiveList.solutions)}>
              <label>Solutions</label>
              <ul>
                <li>
                  <Link href={'/?solutions=payments'}>
                    <a>Payments</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/?solutions=deposits'}>
                    <a>Deposits</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/?solutions=invoices'}>
                    <a>Invoices</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/?solutions=whiteLabel'}>
                    <a>White Label</a>
                  </Link>
                </li>
              </ul>
            </FooterColumn>

            <FooterColumn
              active={activeList === ActiveList.help}
              onClick={() => handleActiveList(ActiveList.help)}>
              <label>Help Center</label>
              <ul>
                <li>
                  <Link href={'/guide'}>
                    <a>Guide</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/#faq'}>
                    <a>FAQ</a>
                  </Link>
                </li>
                <li>
                  <a href={'https://docs.usegateway.net/'} target={'_blank'} rel="noreferrer">
                    API
                  </a>
                </li>
                {statusLevel && (
                  <li>
                    <Link href={'/status'}>
                      <a>
                        System status
                        <StatusCircleWrapper>
                          <Status status={statusLevel as StatusColor} />
                        </StatusCircleWrapper>
                      </a>
                    </Link>{' '}
                  </li>
                )}
              </ul>
            </FooterColumn>

            <FooterColumn
              active={activeList === ActiveList.about}
              onClick={() => handleActiveList(ActiveList.about)}>
              <label>About us</label>
              <ul>
                <li>
                  <a onClick={toggleWidget}>What’s new</a>
                </li>
                <li>
                  <Link href={'/#testimonials'}>
                    <a>Testimonials</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/#social'}>
                    <a>Social media</a>
                  </Link>
                </li>
                <li>
                  <Link href={'/#contact'}>
                    <a>Contact us</a>
                  </Link>
                </li>
              </ul>
            </FooterColumn>

            <FooterColumn
              active={activeList === ActiveList.policies}
              onClick={() => handleActiveList(ActiveList.policies)}>
              <label>Policies</label>
              <ul>
                <li>
                  <span onClick={() => emit(CommonEvents.openTermsOfService)}>Terms</span>
                </li>
                <li>
                  <span onClick={() => emit(CommonEvents.openPrivacyPolicy)}>Privacy</span>
                </li>
                <li>
                  <span onClick={() => emit(CommonEvents.openCookiePolicy)}>Cookies</span>
                </li>
              </ul>
            </FooterColumn>

            <FooterColumn
              active={activeList === ActiveList.support}
              onClick={() => handleActiveList(ActiveList.support)}>
              <label>Support</label>
              <ul>
                <li>
                  <a href="mailto:support@usegateway.group">Submit a request</a>
                </li>
                <li>
                  <Support>
                    <a href={'https://telegram.me/usegateway'} target="_blank" rel="noreferrer">
                      <IconTelegram />
                    </a>
                    <a href={'mailto:support@usegateway.group'} target="_blank" rel="noreferrer">
                      <IconEmail2 />
                    </a>
                  </Support>
                </li>
              </ul>
            </FooterColumn>
          </FooterColumns>
        </FooterMain>

        <FooterBottom>
          <About>
            ©{new Date().getFullYear()} Trend pay LLC, All Rights Reserved ID №404613245 Krtsanisi
            district, Mtkvari str., N6, Tbilisi, Georgia
          </About>
        </FooterBottom>
      </FooterContent>
    </FooterWrapper>
  );
}
