/* eslint-disable @next/next/no-img-element */
import styled from 'styled-components';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// eslint-disable-next-line import/order
import { down } from 'styled-breakpoints';
// eslint-disable-next-line import/order
import { useIntersectionObserver } from '@use-gateway/utils';
import location from '../assets/icons/location.svg';
import star from '../assets/icons/trustpilot.svg';
import bg from '../assets/img/bg-commas.png';

const CustomersSayWrapper = styled.section`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 48px 0 64px;
  background-color: ${({ theme }) => theme.colors.info[100]};

  ${down('md')} {
    padding: 36px 0;
  }
`;

const CustomersSayContent = styled.div`
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }

  .swiper-pagination-bullet {
    background-color: #fff;
  }
`;

const CustomersSayTitle = styled.h2`
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: #fff;

  ${down('md')} {
    font-size: 24px;
  }
`;

const CustomersSayDescription = styled.p`
  margin: 16px 0 80px;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  color: rgba(255, 255, 255, 0.7);

  ${down('md')} {
    margin: 16px 0 48px;
    font-size: 18px;
    line-height: 1.11;
  }
`;

const Item = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 72px;
  padding: 24px;
  border-radius: 12px;
  background-color: #ffffff;
  background-image: url(${bg.src});
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: top 10px right 14px;
`;

const ItemUser = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

const ItemUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  label {
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
  }

  span {
    display: flex;
    align-self: center;
    gap: 4px;
    font-size: 14px;
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.light[40]};
  }
`;

const ItemRatings = styled.div`
  display: flex;
  gap: 2px;
  margin-top: 6px;
`;

const ItemDate = styled.div`
  font-size: 12px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.dark[40]};
`;

const ItemContent = styled.div`
  h4 {
    margin: 0 0 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.43;
    color: ${({ theme }) => theme.colors.dark[40]};
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: 0px;

  ${down('md')} {
    top: -76px;
  }
`;

export function CustomersSay() {
  const { ref, isVisible } = useIntersectionObserver();

  return (
    <CustomersSayWrapper>
      <Anchor id={'testimonials'} />
      <CustomersSayContent
        ref={ref}
        className={isVisible ? 'animation-block active' : 'animation-block'}>
        <CustomersSayTitle>What our customers say</CustomersSayTitle>
        <CustomersSayDescription>
          We love our customers and our customers love us
        </CustomersSayDescription>

        <Swiper
          modules={[Pagination]}
          spaceBetween={36}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}>
          <SwiperSlide>
            <Item>
              <ItemUser>
                <div>
                  <ItemUserInfo>
                    <label>Bahyt Karimov</label>
                    <span>
                      <img src={location} alt="" />
                      UZ
                    </span>
                  </ItemUserInfo>
                  <ItemRatings>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                  </ItemRatings>
                </div>
                <ItemDate>Jan 06, 2023</ItemDate>
              </ItemUser>
              <ItemContent>
                <h4>I like the UseGateway wallet</h4>
                <p>
                  I like the UseGateway wallet. Keep your money safe. Registration was simple,
                  checks were not required, deposits and withdrawals were quick. Commissions are
                  very small and almost invisible during transfers. There are wallet games and
                  online games. I wrote to Telegram support and they quickly helped me with
                  restocking.I like it, I recommend it to everyone!
                </p>
              </ItemContent>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <ItemUser>
                <div>
                  <ItemUserInfo>
                    <label>Liza</label>
                    <span>
                      <img src={location} alt="" />
                      CA
                    </span>
                  </ItemUserInfo>
                  <ItemRatings>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                  </ItemRatings>
                </div>
                <ItemDate>Dec 26, 2022</ItemDate>
              </ItemUser>
              <ItemContent>
                <h4>I like UseGateway wallet</h4>
                <p>
                  I like UseGateway wallet, firstly, it is safe to store money, secondly, low
                  commissions. Also service does not require verification. On the site registration
                  is easy and fast, the money is credited and withdrawn quickly. Support in Telegram
                  answers instantly and it helped me. I have no idea how to use it and I will not be
                  disappointed. I am not particularly pleased with it. In general, UseGateway wallet
                  is super, you can use it.
                </p>
              </ItemContent>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <ItemUser>
                <div>
                  <ItemUserInfo>
                    <label>Vladimir Kucherenko</label>
                    <span>
                      <img src={location} alt="" />
                      UA
                    </span>
                  </ItemUserInfo>
                  <ItemRatings>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                  </ItemRatings>
                </div>
                <ItemDate>Dec 23, 2022</ItemDate>
              </ItemUser>
              <ItemContent>
                <h4>I like this wallet</h4>
                <p>
                  I like this wallet, it is easy to use, the interface is friendly, and it is easy
                  to understand all the required steps. The thing I like the most, that I don{`'`}t
                  need to perform verification for using it, it is real comfortable, and the of
                  money is processing fast without any long delays. As for commission for
                  operations, their are not too high as for such service.
                </p>
              </ItemContent>
            </Item>
          </SwiperSlide>
          <SwiperSlide>
            <Item>
              <ItemUser>
                <div>
                  <ItemUserInfo>
                    <label>Dmitrij Momot</label>
                    <span>
                      <img src={location} alt="" />
                      NL
                    </span>
                  </ItemUserInfo>
                  <ItemRatings>
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                  </ItemRatings>
                </div>
                <ItemDate>Dec 27, 2022</ItemDate>
              </ItemUser>
              <ItemContent>
                <h4>Fast.Easy.Secure.</h4>
                <p>
                  I have spend a lot of time to found something like that. Extremely simple and very
                  user-friendly.
                  <br />
                  Much easier than another solutions, im happy with it.
                  <br />
                  Recommend to everyone!
                </p>
              </ItemContent>
            </Item>
          </SwiperSlide>
        </Swiper>
      </CustomersSayContent>
    </CustomersSayWrapper>
  );
}
