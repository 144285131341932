import { RefObject, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { useIntersectionObserver, useSsrDetector } from '@use-gateway/utils';
import { SolutionDeposit } from './solution-deposit';
import { SolutionInvoices } from './solution-Invoices';
import { SolutionPayments } from './solution-payments';
import { SolutionWhiteLabel } from './solution-white-label';
import { SolutionsNav } from './solutions-nav';

const SolutionsWrapper = styled.section`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 56px 0;
  background-color: ${({ theme }) => theme.colors.light[80]};

  ${down('md')} {
    min-height: auto;
    padding: 36px 0;
  }
`;

const SolutionsContent = styled.div`
  width: 1300px;
  max-width: calc(100% - (150px * 2));
  margin: 0 auto;

  ${down('lg')} {
    max-width: calc(100% - (16px * 2));
  }
`;

export enum ItemsSolution {
  payments = 'payments',
  deposits = 'deposits',
  invoices = 'invoices',
  whiteLabel = 'whiteLabel',
}

export function Solutions() {
  const { query } = useRouter();
  const ssr = useSsrDetector();
  const { ref, isVisible } = useIntersectionObserver(ssr);

  const [currentSolution, setCurrentSolution] = useState(ItemsSolution.payments);
  const wrapperRef = useRef<HTMLElement>();

  useEffect(() => {
    if (typeof query.solutions === 'string' && !ssr) {
      const $wrapper = wrapperRef.current as HTMLElement;
      const executeScroll = () => $wrapper.scrollIntoView();
      const value = query.solutions;
      if (Object.values(ItemsSolution).includes(value as ItemsSolution)) {
        setCurrentSolution(value as ItemsSolution);
        executeScroll();
      }
    }
  }, [query, ssr]);

  return (
    <SolutionsWrapper id={'solutions'} ref={wrapperRef as RefObject<HTMLDivElement>}>
      <SolutionsContent
        ref={ref}
        className={isVisible ? 'animation-block active' : 'animation-block'}>
        <SolutionsNav currentValue={currentSolution} setCurrentValue={setCurrentSolution} />
        <SolutionPayments isActive={currentSolution === ItemsSolution.payments} />
        <SolutionDeposit isActive={currentSolution === ItemsSolution.deposits} />
        <SolutionInvoices isActive={currentSolution === ItemsSolution.invoices} />
        <SolutionWhiteLabel isActive={currentSolution === ItemsSolution.whiteLabel} />
      </SolutionsContent>
    </SolutionsWrapper>
  );
}
