import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { down } from 'styled-breakpoints';
import { css } from 'styled-components';
import { Button } from '@use-gateway/components';
import { styled } from '@use-gateway/theme';
import { useSsrDetector, useSystemStatus } from '@use-gateway/utils';

const SystemStatusWrapper = styled.div`
  width: calc(100% - 72px);
  max-width: 560px;
  margin: 0 auto;
  padding: 32px 0 80px;

  h1 {
    width: 100%;
    text-align: center;
    margin: 0 0 64px;
  }

  ${down('sm')} {
    max-width: 100%;
    padding: 16px 0 0;

    h1 {
      margin: 0 0 32px;
    }
  }
`;

const SystemStatusTable = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SystemStatusItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 100px);
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #120d26;

  &:nth-child(odd) {
    padding-right: 200px;
  }

  ${down('sm')} {
    width: calc(50% - 25px);

    &:nth-child(odd) {
      padding-right: 50px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    padding-right: 0 !important;
  }
`;

interface SystemStatusToggleProps {
  on?: boolean;
}
const SystemStatusToggle = styled.span<SystemStatusToggleProps>`
  padding: 8px;
  border-radius: 4px;

  background: #f2f3f5;
  color: #53556a;

  ${({ on }) =>
    on &&
    css`
      background: #61bd4a;
      color: #ffffff;
    `}
`;

const IssueButtonWrapper = styled.div`
  max-width: 360px;
  margin: 64px auto 0;

  a {
    text-decoration: none;
  }

  ${down('sm')} {
    margin: 32px auto 0;
  }

  ${down('xs')} {
    max-width: 100%;
  }
`;

export function SystemStatus() {
  const { t } = useTranslation();
  const { status } = useSystemStatus();
  const ssr = useSsrDetector();

  if (ssr) return null;

  return (
    <SystemStatusWrapper>
      <h1>System status</h1>
      {!ssr && (
        <SystemStatusTable>
          {Object.keys(status || {}).map((key) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const isOn = status![key];
            const toggleText = isOn ? 'On' : 'Off';

            return (
              <SystemStatusItem key={key}>
                {t(`system-status:${key}`)}{' '}
                <SystemStatusToggle on={isOn}>{toggleText}</SystemStatusToggle>
              </SystemStatusItem>
            );
          })}
        </SystemStatusTable>
      )}
      <IssueButtonWrapper>
        <Link
          href={'https://usegateway.zendesk.com/hc/en-us/requests/new'}
          passHref
          rel="noreferrer">
          <a target={'_blank'}>
            <Button variant={'secondary'}>Report an Issue</Button>
          </a>
        </Link>
      </IssueButtonWrapper>
    </SystemStatusWrapper>
  );
}
